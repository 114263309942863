import { Navigate } from 'react-router-dom';
import { useGeneralContext } from '../storage/context/GeneralContext';
import { UnforbiddenPage } from '../screens/UnforbiddenPage';
import { isAdmin } from '../api/firebase';
import { useEffect, useState } from 'react';

interface ProtectedPageProps {
    page: JSX.Element
}

export default function ProtectedPage({ page }: ProtectedPageProps) {
    const { authenticated, currentUser } = useGeneralContext();
    const [admin, setAdmin] = useState<boolean>(false);

    useEffect(() => {
        const abortController = new AbortController();
        const checkAdmin = async () => {
            if (authenticated && currentUser) {
                const admin = await isAdmin(currentUser?.uid);
                if (!abortController.signal.aborted) {
                    setAdmin(admin);
                }
            }
        }
        checkAdmin();
        return () => {
            abortController.abort();
        }
    }, [authenticated, currentUser]);

    if (!authenticated) {
        return <Navigate to={"/login"} />
    } else if (authenticated && currentUser && !admin) {
        return <UnforbiddenPage />
    }
    return page
}

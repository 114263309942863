import { useCallback, useEffect, useState } from 'react';
import { Table, Form, Modal, Button, Dimmer, Loader } from 'semantic-ui-react';
import TextInputDialog from './TextInputModal';
import { DeleteableInputGroup } from './DeleteableInputGroup';
import { useUnregisteredBusinessContext } from '../storage/context/UnregisteredShopsProvider';
import { ValidFieldsBusinessCreationDataUpdate } from '../models/models';
import { getUnregisteredBusinesses } from '../api/firebase';
import { getTimeStringFromFirebaseTimestamp } from '../common/format';
import { Link } from 'react-router-dom';

interface TableRowProps {
    item: any;
}

function TableRow({ item }: TableRowProps) {
    return (
        <Table.Row key={item.store_id}>
            <Table.Cell key={item.id + item.tin_number}>{item.tin_number}</Table.Cell>
            <Table.Cell key={item.id + item.store_name}>{item.store_name}</Table.Cell>
            <Table.Cell key={item.id + item.operating_location}>{item.operating_location}</Table.Cell>
            <Table.Cell key={item.id + item.submitted_at}>{getTimeStringFromFirebaseTimestamp(item.submitted_at)}</Table.Cell>
            <Table.Cell>
                <Button
                    primary
                    icon="eye"
                    as={Link}
                    to={`/unregistered_business/${item.submission_id}`}
                />
            </Table.Cell>
        </Table.Row>
    );
}

interface ModificationModalProps {
    show: boolean;
    handleClose: () => void;
    handleAddingNewField: () => void;
}

function ModificationModal({ show, handleClose, handleAddingNewField }: ModificationModalProps) {
    const { currentObject, changeFieldValue, saveChanges, removeNewField, deleteObject } = useUnregisteredBusinessContext();
    const [errorMsg, setErrorMsg] = useState("");
    const form = Object.keys(currentObject)
        .filter((val) => val !== "id")
        .map((objKey: string) => {
            const fieldCasted = objKey as ValidFieldsBusinessCreationDataUpdate;
            return (
                <DeleteableInputGroup
                    key={objKey}
                    objKey={objKey}
                    currentValue={currentObject[fieldCasted]}
                    onValueChange={(value) => changeFieldValue(fieldCasted, value)}
                    onDelete={() => removeNewField(fieldCasted)}
                />
            );
        });

    const onSaveChanges = async () => {
        saveChanges()
            .then(() => {
                handleClose();
            })
            .catch((error) => {
                setErrorMsg(error.toString());
            });
    };

    const onDelete = async () => {
        deleteObject()
            .then(() => {
                handleClose();
            })
            .catch((err) => {
                setErrorMsg(err.toString());
            });
    };

    return (
        <Modal open={show} onClose={handleClose} size="large">
            <Modal.Header>Modification : {currentObject.store_name}</Modal.Header>
            <Modal.Content>
                <Form>{form}</Form>
                <Button onClick={handleAddingNewField}>Add Field</Button>
                <p style={{ color: "red", margin: 10 }}>{errorMsg}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={handleClose}>
                    Close
                </Button>
                <Button negative onClick={onDelete}>
                    Delete
                </Button>
                <Button primary onClick={onSaveChanges}>
                    Save Changes
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default function UnregisteredBusinessesTable() {
    const { setCurrentObject, addNewField } = useUnregisteredBusinessContext();
    const [data, setData] = useState<any[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [showTextInputModal, setShowTextInputModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchDataInitialData = useCallback(async () => {
        setLoading(() => true);
        try {
            const unregisteredBusinesses = await getUnregisteredBusinesses();
            if (unregisteredBusinesses.status === "success") {
                setData(() => unregisteredBusinesses.data);
            }
        } finally {
            setLoading(() => false);
        }
    }, []);

    useEffect(() => {
        fetchDataInitialData();
    }, [fetchDataInitialData]);

    const handleAddingNewField = () => {
        setShowModal(false);
        setShowTextInputModal(true);
    };

    return (
        <>
            <Dimmer active={loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <TextInputDialog
                title={"Field Name"}
                show={showTextInputModal}
                onClose={() => {
                    setShowTextInputModal(false);
                    setShowModal(true);
                }}
                onSubmit={(val: string) => {
                    addNewField(val as ValidFieldsBusinessCreationDataUpdate);
                    setShowTextInputModal(false);
                    setShowModal(true);
                }}
            />
            <ModificationModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                handleAddingNewField={handleAddingNewField}
            />
            <Table striped selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>TIN Number</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Location</Table.HeaderCell>
                        <Table.HeaderCell>Submitted At</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((item, idx) => (
                        <TableRow
                            key={"unRegisteredBusiness" + idx.toString()}
                            item={item}
                        />
                    ))}
                </Table.Body>
            </Table>
        </>
    );
}
import { initializeApp } from 'firebase/app';

import {
    getFirestore, collection, getDocs, getDoc,
    setDoc, doc, limit, query,
    orderBy, startAfter, deleteDoc, addDoc, serverTimestamp, where
} from 'firebase/firestore';

import {
    getAuth, signInWithPopup, GoogleAuthProvider,
    setPersistence, browserSessionPersistence, signOut, onAuthStateChanged
} from "firebase/auth";
import { BusinessCreationData, BusinessToUserMap, FirebaseFunctionResponse, ProfileInformation } from '../models/models';
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
    apiKey: process.env["REACT_APP_FIREBASE_apiKey"],
    authDomain: process.env["REACT_APP_FIREBASE_authDomain"],
    projectId: process.env["REACT_APP_FIREBASE_projectId"],
    storageBucket: process.env["REACT_APP_FIREBASE_storageBucket"],
    messagingSenderId: process.env["REACT_APP_FIREBASE_messagingSenderId"],
    appId: process.env["REACT_APP_FIREBASE_appId"],
    measurementId: process.env["REACT_APP_FIREBASE_measurementId"]
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const auth = getAuth(app);
const functions = getFunctions(app);

auth.useDeviceLanguage();

const businessesUnregisteredCollectionRef = collection(db, "storesUnregistered");
const businessesUnregisteredLogsCollectionRef = collection(db, "storesUnregisteredLogs");
const businessesRegisteredCollectionRef = collection(db, "storesRegistered");
const businessesToUsersMappingCollectionRef = collection(db, "storesToUsersMapping");
const businessesProfileDataCollectionRef = collection(db, "storesProfileData");

(async () => {
    await setPersistence(auth, browserSessionPersistence);
})();

const googleProvider = new GoogleAuthProvider();

// ************************** Authentication Related **************************

export async function loginWithGoogleFirebase() {
    return setPersistence(auth, browserSessionPersistence)
        .then(() => signInWithPopup(auth, googleProvider))
}

export async function logoutWithGoogleFirebase() {
    return setPersistence(auth, browserSessionPersistence)
        .then(() => signOut(auth))
}

export async function onAuthStateChangedWrapper() {
    return onAuthStateChanged(auth, (user) => user);
}


// ************************** Business Related **************************
async function logEventUnregisteredShop(context: any, message: string, data: any) {
    addDoc(
        businessesUnregisteredLogsCollectionRef,
        {
            context: JSON.stringify(context),
            message: message,
            data: data,
            timestamp: serverTimestamp()
        }
    );
}

export async function updateUnregisteredShop(obj: BusinessCreationData, admin: any) {
    if (obj._ref) {
        throw new Error("There's no reference.")
    }
    const data = { ...obj } as any;
    delete data._ref;
    logEventUnregisteredShop(admin, "Updating the unregistered shop", obj);
    return setDoc(
        doc(db, `${businessesRegisteredCollectionRef.path}/${obj._ref}`),
        data,
        { merge: true }
    );
}

export async function linkBusinessToUser(data: BusinessToUserMap) {
    const querySnapshot = await getDocs(
        query(
            collection(db, businessesToUsersMappingCollectionRef.path),
            where("store_id", "==", data.store_id),
            where("uid", "==", data.uid),
            limit(1)
        )
    );
    if (querySnapshot.empty) {
        return addDoc(
            businessesToUsersMappingCollectionRef,
            data
        )
    } else {
        return setDoc(
            doc(db, `${businessesToUsersMappingCollectionRef.path}/${querySnapshot.docs[0].id}`),
            data,
            { merge: true }
        )
    }
}

export async function infiniteUnregisteredShopsStart(): Promise<[any[], any]> {
    const querySnapshot = await getDocs(
        query(
            collection(db, businessesUnregisteredCollectionRef.path),
            orderBy("store_id"),
            limit(10)
        )
    );
    return [
        querySnapshot.docs.map((documentSnapshot) => Object.assign({ _reference: documentSnapshot.id }, documentSnapshot.data())),
        querySnapshot.docs[querySnapshot.docs.length - 1]
    ];
}

export async function infiniteUnregisteredShopsNextBatch(objectToStartFrom: any): Promise<[any[], any]> {
    const querySnapshot = await getDocs(
        query(
            collection(db, businessesUnregisteredCollectionRef.path),
            orderBy("store_id"),
            startAfter(objectToStartFrom),
            limit(10)
        )
    );

    return [
        querySnapshot.docs.map((documentSnapshot) => Object.assign({ _reference: documentSnapshot.id }, documentSnapshot.data())),
        querySnapshot.docs[querySnapshot.docs.length - 1]
    ];
}

export async function setBusinessProfileData(data: ProfileInformation) {
    const querySnapshot = await getDocs(
        query(
            collection(db, businessesProfileDataCollectionRef.path),
            where("store_id", "==", data.store_id),
            limit(1)
        )
    );
    if (querySnapshot.empty) {
        return addDoc(
            businessesProfileDataCollectionRef,
            data
        )
    } else {
        return setDoc(
            doc(db, `${businessesProfileDataCollectionRef.path}/${querySnapshot.docs[0].id}`),
            data,
            { merge: true }
        )
    }
}

export async function deleteUnregisteredStore(obj: BusinessCreationData): Promise<void> {
    if (!obj._ref) throw new Error("The document doesn't have a _ref.")
    return deleteDoc(
        doc(
            db,
            `${businessesUnregisteredCollectionRef.path}/${obj._ref}`
        )
    )
}

export async function validateABusiness(submission_id: string): Promise<FirebaseFunctionResponse> {
    if (!submission_id) throw new Error("The submission_id is empty.")
    const fun = httpsCallable(functions, "adminRegisterABusiness");
    return (await fun({ submission_id: submission_id })).data as FirebaseFunctionResponse;
}

export async function getRegisteredBusiness(store_id?: string): Promise<FirebaseFunctionResponse> {
    const fun = httpsCallable(functions, "adminFetchRegisteredShops");
    return (await fun({ store_id: store_id })).data as FirebaseFunctionResponse;
}

export async function getUnregisteredBusinesses(submission_id?: string): Promise<FirebaseFunctionResponse> {
    const fun = httpsCallable(functions, "adminFetchUnRegisteredShops");
    return (await fun({ submission_id: submission_id })).data as FirebaseFunctionResponse;
}

export async function deleteRegisteredBusinessFromPlatform(store_id: string): Promise<FirebaseFunctionResponse> {
    const fun = httpsCallable(functions, "deleteRegisteredStoreFromPlatform");
    return (await fun({ store_id: store_id })).data as FirebaseFunctionResponse;
}

export async function deleteRegistrationRequest(submission_id: string): Promise<FirebaseFunctionResponse> {
    const fun = httpsCallable(functions, "adminDeleteRequestToRegister");
    return (await fun({ submission_id: submission_id })).data as FirebaseFunctionResponse;
}

export async function isAdmin(uid: string): Promise<boolean> {
    const userDocs = await getDoc(
        doc(db, `admins/${uid}`)
    );
    return userDocs.exists();
}
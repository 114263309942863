import moment from "moment";

export function getTimeStringFromFirebaseTimestamp(timestampObject: { _seconds: number, _nanoseconds: number }): string {
   let timestamp = timestampObject._seconds;
   let momentObj = moment.unix(timestamp);
   momentObj.locale('en');
   let formattedDate = momentObj.format('LLLL');
   return formattedDate;
}

export function getDateStringFromTimestamp(timestampObject: { seconds: number, nanoseconds: number }): string {
   let timestamp = timestampObject.seconds;
   let momentObj = moment.unix(timestamp);
   momentObj.locale('en');
   let formattedDate = momentObj.format('LL');
   return formattedDate;
}
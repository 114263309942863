import { DefaultResponsiveContainer } from '../../components/DefaultResponsiveContainer';
import { Segment, Tab } from "semantic-ui-react";
import UnregisteredBusinessesTable from '../../components/UnregisteredBusinessesTable';
import { RegisteredBusinessesTable } from '../../components/RegisteredBusinessesTable';



function RegisteredBusinessesPanel() {
    return (
        <Segment basic>
            <RegisteredBusinessesTable />
        </Segment>
    )
}

function UnregisteredBusinessesPanel() {
    return (
        <Segment basic>
            <UnregisteredBusinessesTable />
        </Segment>
    )
}

function InnerBusinessSubPage() {

    const panes = [
        {
            menuItem: 'Registered Businesses',
            render: () => <Tab.Pane><RegisteredBusinessesPanel /></Tab.Pane>
        },
        {
            menuItem: 'Unregistered Businesses',
            render: () => <Tab.Pane><UnregisteredBusinessesPanel /></Tab.Pane>
        }
    ]

    return (
        <Segment basic>
            <Tab
                renderActiveOnly
                panes={panes}
                menu={{ fluid: true, tabular: true, widths: 4 }}
            />
        </Segment>
    )
}

export function BusinessesPage() {
    return (
        <DefaultResponsiveContainer>
            <InnerBusinessSubPage />
        </DefaultResponsiveContainer>
    );
}

import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Dimmer, Grid, Icon, Loader, Message, Segment, Select, Table } from "semantic-ui-react";
import { useRegisteredBusinessContext } from "../../storage/context/RegisteredShopsProvider";
import { DefaultResponsiveContainer } from "../../components/DefaultResponsiveContainer";
import { ConfirmationPopup } from "../../components/ConfirmationPopup";
import { deleteRegisteredBusinessFromPlatform } from "../../api/firebase";
import { RegisteredBusiness } from "../../models/models";
import { useQuery } from "@tanstack/react-query";
import { createSubscriptionForBusiness, getActivePlans, getBusinessDetails, getBusinessPlans } from "../../api/subscription-api";
import { useGeneralContext } from "../../storage/context/GeneralContext";
import { USEQUERY_PLANS_ACTIVE } from "../../common/constant";


function IsBusinessRegisteredOnSubscriptionAPI({ business }: { business: RegisteredBusiness }) {
    const { currentUser } = useGeneralContext();
    const {
        data,
        isLoading,
    } = useQuery({
        queryKey: ["subscription-api-business", business.store_id],
        queryFn: async () => {
            if (!currentUser) return;
            const idToken = await currentUser?.getIdToken();
            if (!idToken) return;
            return getBusinessDetails(business.store_id, idToken);
        },
        enabled: !!business.store_id && !!currentUser
    });

    if (isLoading) return <p>Loading...</p>;

    return (
        <Segment basic style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            {data ?
                (
                    <Container style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                        <Icon name="check" color="green" />
                        <p>Business is registered on Subscription API</p>
                    </Container>
                ) :
                (
                    <Container style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                        <Icon name="close" color="red" />
                        <p>Business is not registered on Subscription API</p>
                    </Container>
                )}
        </Segment>
    );
}

function BusinessHistoryPlans({ business }: { business: RegisteredBusiness }) {
    const { currentUser } = useGeneralContext();
    const {
        data,
        isLoading,
        error
    } = useQuery({
        queryKey: ["subscription-api-business-plans", business.store_id],
        queryFn: async () => {
            if (!currentUser) return [];
            const idToken = await currentUser.getIdToken();
            if (!idToken) return [];
            return getBusinessPlans(business.store_id, idToken) || [];
        },
        retry: 1,
        enabled: !!business.store_id && !!currentUser
    });

    if (isLoading) return <p>Loading...</p>;

    if (error && !isLoading) {
        return (
            <Message negative>
                Failed to load data. Our engineers are working on it.
            </Message>
        )
    }
    if (data && data?.length === 0 && !isLoading) {
        return (
            <Segment basic style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
                <p>No plans available</p>
                <Container style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "flex-end", width: "100%" }}>
                    <CreateBasicPlanSubscriptions business={business} />
                </Container>
            </Segment>
        );
    }

    return (
        <Table fixed>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Pricing ID</Table.HeaderCell>
                    <Table.HeaderCell>Start Date</Table.HeaderCell>
                    <Table.HeaderCell>End Date</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Auto Renew</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    (data || []).map((plan: any, idx) => (
                        <Table.Row key={idx}>
                            <Table.Cell>{plan.pricing_id}</Table.Cell>
                            <Table.Cell>{plan.start_date}</Table.Cell>
                            <Table.Cell>{plan.end_date}</Table.Cell>
                            <Table.Cell>{plan.status}</Table.Cell>
                            <Table.Cell>{plan.auto_renew ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                    ))
                }
            </Table.Body>
        </Table>
    );
}

function CreateBasicPlanSubscriptions({ business }: { business: RegisteredBusiness }) {
    const {
        data,
        isLoading,
    } = useQuery({
        queryKey: [USEQUERY_PLANS_ACTIVE],
        queryFn: async () => {
            return await getActivePlans();
        },
    });

    const [selectedPlan, setSelectedPlan] = useState<any | undefined>(undefined);
    const { currentUser } = useGeneralContext();
    const [requestLoading, setRequestLoading] = useState(false);


    const handleSubmit = useCallback(async () => {
        setRequestLoading(() => true);
        try {
            if (!currentUser) return;
            if (selectedPlan === undefined) return;
            const planObj = data?.plans?.find((plan: any) => plan.pricing_id === selectedPlan);
            if (!planObj) return;
            const dataToSend = {
                business_id: business.store_id,
                pricing_id: planObj.pricing_id,
                status: 'active' as const,
                auto_renew: true
            };
            const idToken = await currentUser.getIdToken();
            if (!idToken) return;
            await createSubscriptionForBusiness(business.store_id, dataToSend, idToken);
        } finally {
            setRequestLoading(() => false);
        }
    }, [selectedPlan, data, currentUser, business]);

    if (isLoading) return <p>Loading...</p>;
    if (!data) return <p>No plans available</p>;

    return (
        <Segment
            basic
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%"
            }}
        >
            <Select
                placeholder="Select Plan"
                options={data.plans?.map((plan: any) => ({
                    key: plan.plan_id,
                    text: plan.name,
                    value: plan.pricing_id
                }))}
                onChange={(e, { value }) => setSelectedPlan(value)}
                loading={isLoading}
            />
            <Button
                primary
                content="Create Subscription"
                onClick={handleSubmit}
                loading={isLoading || requestLoading}
                active={selectedPlan !== undefined}
            />
        </Segment>
    );
}

function BusinessPlanDetails({ data }: { data: RegisteredBusiness }) {
    return (
        <Segment basic style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
            <IsBusinessRegisteredOnSubscriptionAPI business={data} />
            <BusinessHistoryPlans business={data} />
        </Segment>
    );
}

const interestedFields = [
    "business_type",
    "tin_number",
    "phone_number",
    "store_name",
    "business_sector",
    "business_email",
    "registered_country",
    "operating_location",
    "_ref",
    "created_uid",
    "store_id",
    "creator_national_id",
];

function BusinessDetailsHolder({ data }: { data: RegisteredBusiness }) {
    // Filter object to remove unwanted fields
    const filteredData = Object.keys(data).reduce((acc, key) => {
        if (!interestedFields.includes(key)) return acc;
        return { ...acc, [key]: key };
    }, {} as any);

    return (
        <Table fixed>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Business Details</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    Object.keys(filteredData).map((key) => (
                        <Table.Row key={key}>
                            <Table.Cell>{key}</Table.Cell>
                            <Table.Cell>{data[key as keyof RegisteredBusiness]}</Table.Cell>
                        </Table.Row>
                    ))
                }
            </Table.Body>
        </Table>
    );
}

export function RegisteredBusinessPage() {
    const { businessid } = useParams();
    const { setCurrentBusinessId, currentObject } = useRegisteredBusinessContext();
    const [loading, setLoading] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (businessid === undefined) return;
        setCurrentBusinessId(businessid);
    }, [businessid, setCurrentBusinessId]);

    const handleSubmit = useCallback(() => {
        if (businessid === undefined) return;
        setLoading(() => true);
        deleteRegisteredBusinessFromPlatform(businessid)
            .then((resp) => {
                setLoading(() => false);
                if (resp.status === "success") {
                    navigate("/businesses");
                } else {
                    alert(resp.message || resp.status || "Unidentified error");
                }
            })
            .catch((err) => {
                alert(err.message);
                setLoading(() => false);
            });
    }, [businessid, navigate]);

    return (
        <DefaultResponsiveContainer>
            <ConfirmationPopup
                open={isPopupOpen}
                size="large"
                header={`Delete Shop : ${businessid}`}
                text="Are you sure you want to delete the shop from the platform ?"
                onConfirm={() => { setIsPopupOpen(false); handleSubmit(); }}
                onCancel={() => setIsPopupOpen(false)}
                isNegative
            />
            <Dimmer active={loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <Segment basic>
                <p>Business ID: {businessid}</p>
                {currentObject ? <BusinessDetailsHolder data={currentObject} /> : null}
            </Segment>
            {currentObject ? <BusinessPlanDetails data={currentObject} /> : null}
            <Segment basic>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Button secondary onClick={() => alert("Not available yet")}>Hide Business</Button>
                        </Grid.Column>
                        <Grid.Column>
                            <Button negative onClick={() => setIsPopupOpen(true)}>Delete Business</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </DefaultResponsiveContainer>
    );
}
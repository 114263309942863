import axios from "axios";

export interface CreationPlan {
    name: string;
    description: string;
    is_active: boolean;
    pricing: {
        amount: number;
        currency: string;
        billing_interval: string;
    }
}

interface PlansPagination {
    plans: Plan[],
    pagination: {
        total: number,
        page: number,
        limit: number,
        totalPages: number
    }
}

export interface Plan {
    plan_id: number;
    name: string;
    description: string;
    pricing_id: number;
    amount: string;
    currency: string;
    billing_interval: string;
}

export async function getAllPlans(idToken: string, limit?: number, page?: number): Promise<PlansPagination> {
    const response = await axios.get(
        `${process.env.REACT_APP_SUBSCRIPTION_API}/plans/all`,
        {
            headers: {
                Authorization: `Bearer ${idToken}`
            },
            params: {
                limit,
                page
            }
        }
    ).then((res) => res.data);
    return response;
}

export async function getActivePlans() {
    return axios.get(`${process.env.REACT_APP_SUBSCRIPTION_API}/plans/active`).then((res) => res.data);
}

export async function createPlan(plan: CreationPlan, idToken: string): Promise<any> {
    const response = await axios.post(
        `${process.env.REACT_APP_SUBSCRIPTION_API}/plans/register`,
        plan,
        {
            headers: {
                Authorization: `Bearer ${idToken}`,
            }
        }
    );
    return response.data;
}

export async function getBusinessDetails(businessid: string, idToken: string): Promise<{
    business_id: string,
    owner_id: string,
    created_at: string
} | null> {
    const response = await axios.get(
        `${process.env.REACT_APP_SUBSCRIPTION_API}/businesses/${businessid}`,
        {
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        }
    );
    // Check if status is 404
    if (response.status === 404) {
        return null;
    }
    return response.data;
}

export async function getBusinessPlans(businessid: string, idToken: string): Promise<Plan[]> {
    const response = await axios.get(
        `${process.env.REACT_APP_SUBSCRIPTION_API}/subscriptions/business/${businessid}`,
        {
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        }
    );
    return response.data;
}

export async function syncWithFirestore(idToken: string): Promise<any> {
    const response = await axios.post(
        `${process.env.REACT_APP_SUBSCRIPTION_API}/businesses/sync-firestore`,
        {},
        {
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        }
    );
    return response.data;
}

interface SubscriptionCreation {
    business_id: string,
    pricing_id: number,
    auto_renew: boolean,
    status: 'active' | 'cancelled' | 'expired' | 'suspended'
}

export async function createSubscriptionForBusiness(businessid: string, data: SubscriptionCreation, idToken: string): Promise<any> {
    const response = await axios.post(
        `${process.env.REACT_APP_SUBSCRIPTION_API}/subscriptions/register`,
        data,
        {
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        }
    );
    return response.data;
}
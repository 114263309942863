import { useState } from "react";
import { Button, Dimmer, Loader, Message, Table } from "semantic-ui-react";
import { RegisteredBusiness } from "../models/models";
import { getRegisteredBusiness } from "../api/firebase";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

function RegisteredBusinessTableRow({ data }: { data: RegisteredBusiness }) {
    return (
        <Table.Row>
            <Table.Cell>{data.store_id}</Table.Cell>
            <Table.Cell>{data.store_name}</Table.Cell>
            <Table.Cell>
                <Button
                    as={Link}
                    to={`/registered_business/${data.store_id}`}
                    primary
                    icon="eye"
                />
            </Table.Cell>
        </Table.Row>
    )
}

export function RegisteredBusinessesTable() {
    const [errorMsg, setErrorMsg] = useState("");
    const {
        data: businesses,
        isLoading: loading,
    } = useQuery({
        queryKey: ["registered-businesses"],
        queryFn: async () => {
            const resp = await getRegisteredBusiness();
            if (resp.status === "success") {
                const businesses = resp.data as RegisteredBusiness[];
                businesses.sort((a, b) => a.store_name.localeCompare(b.store_name));
                return businesses;
            } else {
                setErrorMsg(resp.message as string);
                return [];
            }
        }
    });

    return (
        <>
            <Dimmer active={loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <Table striped selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Business ID</Table.HeaderCell>
                        <Table.HeaderCell>Business Name</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {(businesses || []).map((data) => (
                        <RegisteredBusinessTableRow key={data.store_id} data={data} />
                    ))}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.Cell colSpan="2">
                            {errorMsg.length > 0 && <Message negative>{errorMsg}</Message>}
                        </Table.Cell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </>
    )
}
import { Button, Modal} from 'semantic-ui-react';

interface ConfirmationPopupProps {
    size?: "mini" | "tiny" | "small" | "large" | "fullscreen" | undefined;
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    header: string;
    text: string;
    isNegative?: boolean;
}

export function ConfirmationPopup(props: ConfirmationPopupProps){

    return (
        <Modal
            size={ props.size || "mini"}
            open={props.open}
        >   
            <Modal.Header>{props.header}</Modal.Header>
            <Modal.Content>
            <p>{props.text}</p>
            </Modal.Content>
            <Modal.Actions>
            <Button onClick={props.onCancel}>
                Cancel
            </Button>
            <Button negative={props.isNegative} positive={!props.isNegative} onClick={props.onConfirm}>
                Confirm
            </Button>
            </Modal.Actions>
        </Modal>
    )
}
import { Table, Segment, Button, Modal, Form, Checkbox, Select } from 'semantic-ui-react';
import { DefaultResponsiveContainer } from "../../components/DefaultResponsiveContainer";
import { useQuery } from '@tanstack/react-query';
import { createPlan, getAllPlans, CreationPlan } from '../../api/subscription-api';
import { useCallback, useState } from 'react';
import { useGeneralContext } from '../../storage/context/GeneralContext';

const defaultPlan: CreationPlan = {
    name: "",
    description: "",
    is_active: false,
    pricing: {
        amount: 0,
        currency: "RWF",
        billing_interval: "M"
    }
}

function CreatePlanModal({ open, onClose }: { open: boolean, onClose: () => void }) {
    const [plan, setPlan] = useState<CreationPlan>(defaultPlan);
    const { currentUser } = useGeneralContext();

    const onSubmit = useCallback(async () => {
        if (!currentUser) {
            return;
        }
        const idToken = await currentUser?.getIdToken();
        if (!idToken) {
            return;
        }
        await createPlan(plan, idToken);
        onClose();
    }, [plan, currentUser, onClose]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            size='small'
        >
            <Modal.Header>Create New Plan</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form>
                        <Form.Field>
                            <label>Name</label>
                            <input
                                placeholder='Name'
                                value={plan.name}
                                onChange={(e) => {
                                    setPlan({ ...plan, name: e.target.value });
                                }}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Description</label>
                            <input
                                placeholder='Description'
                                value={plan.description}
                                onChange={(e) => {
                                    setPlan({ ...plan, description: e.target.value });
                                }}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Active</label>
                            <Checkbox defaultChecked />
                        </Form.Field>
                        <Form.Field>
                            <label>Billing Interval</label>
                            <Select
                                placeholder='Billing Interval'
                                options={[
                                    { key: 'month', value: 'M', text: 'Month' },
                                ]}
                                value={plan.pricing.billing_interval}
                                onChange={(e, data) => {
                                    setPlan({ ...plan, pricing: { ...plan.pricing, billing_interval: data.value as string } });
                                }}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Amount</label>
                            <input
                                placeholder='Amount'
                                value={plan.pricing.amount}
                                onChange={(e) => {
                                    setPlan({ ...plan, pricing: { ...plan.pricing, amount: parseInt(e.target.value) } });
                                }}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Currency</label>
                            <Select
                                placeholder='Currency'
                                options={[
                                    { key: 'usd', value: 'USD', text: 'USD' },
                                    { key: 'rwf', value: 'RWF', text: 'RWF' },
                                ]}
                                value={plan.pricing.currency}
                                onChange={(e, data) => {
                                    setPlan({ ...plan, pricing: { ...plan.pricing, currency: data.value as string } });
                                }}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    content="Create"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={onSubmit}
                    positive
                />
            </Modal.Actions>
        </Modal>
    );
}

export function PlansPage() {
    const { currentUser } = useGeneralContext();
    const {
        data,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ["subscription-api-all-plans"],
        queryFn: async () => {
            if (!currentUser) {
                return Promise.resolve({ plans: [] });
            }
            const idToken = await currentUser.getIdToken();
            if (!idToken) {
                return Promise.resolve({ plans: [] });
            }
            return await getAllPlans(idToken);
        },
        enabled: !!currentUser
    });

    const [open, setOpen] = useState(false);

    return (
        <DefaultResponsiveContainer>
            <Segment basic>
                <h1>Plans Management</h1>
                <Button primary onClick={() => {
                    setOpen(true);
                }}>Create New Plan</Button>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.HeaderCell>Currency</Table.HeaderCell>
                            <Table.HeaderCell>Billing Interval</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data?.plans.map((plan, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>{plan.name}</Table.Cell>
                                    <Table.Cell>{plan.amount}</Table.Cell>
                                    <Table.Cell>{plan.currency}</Table.Cell>
                                    <Table.Cell>{plan.billing_interval}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    {isLoading ?
                        (
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='8'>Loading...</Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        ) : null}
                    {!isLoading ?
                        (
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='8'>
                                        <Button primary onClick={() => refetch()}>Refresh</Button>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        ) : null}
                </Table>
            </Segment>
            <CreatePlanModal open={open} onClose={() => {
                setOpen(false);
            }} />
        </DefaultResponsiveContainer>
    );
}
import { Button, Header } from "semantic-ui-react";
import { DefaultResponsiveContainer } from "../../components/DefaultResponsiveContainer";
import { useCallback } from "react";
import { useGeneralContext } from "../../storage/context/GeneralContext";
import { syncWithFirestore } from "../../api/subscription-api";

export default function BillingPage() {
    const { currentUser } = useGeneralContext();
    const onSubmitSync = useCallback(async () => {
        if (!currentUser) {
            return;
        }
        const idToken = await currentUser.getIdToken();
        if (!idToken) {
            return;
        }
        await syncWithFirestore(idToken);
    }, [currentUser]);
    return (
        <DefaultResponsiveContainer>
            <Header as="h1">Billing</Header>
            <Button
                content="Sync With Firestore"
                color="red"
                onClick={onSubmitSync}
            />
            <p>Don't click this button unless you know what you are doing.</p>
        </DefaultResponsiveContainer>
    );
}